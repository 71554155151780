import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../components/about/About';

const IndexPage = () => (
    <Layout>
      <SEO title="Home" keywords={['gatsby', 'application', 'react']} />
      <About />
    </Layout>
);

export default IndexPage;
