import React from 'react';
import globalStyles from '../styles/Main.module.css';

const About = () => (
  <div>
    <h1 className={globalStyles.sectionTitle}>ABOUT US</h1>
    <p className={globalStyles.standardText}>
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Woods Pond is located in Bridgton,
      Maine, in the heart of the state’s Lake Region. It is said to be named
      after Bridgton’s first surveyor, Solomon Wood, who first came to the area
      in the fall of 1776. Today the town beach and boat launch provide easy
      access for swimming, fishing, boating and sailing. In 2003, Woods Pond
      became the first waterbody in the area to have a milfoil boat wash
      station, which was designed, installed and paid for by residents of Woods
      Pond, LEA and the Town of Bridgton. The Pond is 462 acres with a maximum
      depth of 29 feet, and runs north to south with the south end bordering on
      Rt. 117.
    </p>
    <p className={globalStyles.standardText}>
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The Woods Pond Water Quality
      Association, WPWQA, is a Section 501(c)(3) non-profit. The purpose of
      WPWQA is to preserve, protect, and enhance the water quality of Woods
      Pond. These objectives are accomplished through a variety of activities
      which include water quality monitoring by Lakes Environmental Association,
      LEA, Courtesy Boat Inspection conducted by LEA staff and Woods Pond
      volunteers and an Invasive Plant Patrol team on the lookout for invasive
      plants.
    </p>
    <p className={globalStyles.standardText}>
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;The main water quality issues on Woods
      Pond are the worsening phosphorus trend and dissolved oxygen depletion,
      which affects about half of the water column at the height of the summer.
      LEA rates Woods Pond at a MODERATE level of concern. The WPWQA provides
      grants to homeowners on Woods Pond to remedy issues that cause erosion and
      runoff into the lake—the single biggest cause of increased phosphorus.
    </p>
    <p className={globalStyles.standardText}>
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Membership in WPWQA is open anyone
      interested in the preservation, protection, and enhancement of the water
      quality of Woods Pond.
    </p>
  </div>
);

export default About;
